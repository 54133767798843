export const selectActiveScreen = state => state.screen.active;
export const selectPreviousScreen = state => state.screen.previous;

export const selectProject = state => state.project;
export const selectSettings = state => selectProject(state).settings;
export const selectHelmetSettings = state => selectSettings(state).helmet;
export const selectDefinitionsSettings = state => selectSettings(state).definitions;

export const selectScreensSettings = state => selectDefinitionsSettings(state).screens;
export const selectProgressSettings = state => selectDefinitionsSettings(state).progress;
export const selectCalculationsSettings = state => selectDefinitionsSettings(state).calculations;
export const selectModelSettings = state => selectDefinitionsSettings(state).model;
export const selectHubPreviewSettings = state => selectDefinitionsSettings(state).hubPreview;

export const selectSelectedOptions = state => state.selectedOptions;
export const selectSelectedOption = (state, key) => selectSelectedOptions(state)[key];
