import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectConfigurationId } from './redux/selectors';

const ConfigurationIdWatcher = () => {
  const id = useSelector(selectConfigurationId);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      history.replace(`?id=${id}`);
    }
  }, [history, id]);

  return null;
};

export default ConfigurationIdWatcher;
