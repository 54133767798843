import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Paragraph.css';

const paragraphStyle = b.with('paragraph');

const Paragraph = ({ text, strong }) => (
  // eslint-disable-next-line react/no-danger
  <p className={paragraphStyle({ strong })} dangerouslySetInnerHTML={{ __html: text }} />
);

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
  strong: PropTypes.bool
};

export default Paragraph;
