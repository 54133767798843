import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import Progress from '../Progress';

import ScreenContent from './ScreenContent';
import ScreenActions from './ScreenActions';

import './Screen.css';

const screenStyle = b.with('screen');

const Screen = ({ screen }) => {
  const { id: type, content, actions } = screen;

  return (
    <div className={screenStyle({ type })}>
      <div className={screenStyle('container', { type })}>
        <div className={screenStyle('progress')}>
          <Progress />
        </div>
        <div className={screenStyle('content', { type })}>
          <ScreenContent content={content} />
        </div>
        <footer className={screenStyle('actions', { type })}>
          <ScreenActions actions={actions} />
        </footer>
      </div>
    </div>
  );
};

Screen.propTypes = {
  screen: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({})),
    actions: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

export default Screen;
