import * as mexp from 'math-expression-evaluator';

// implement ==, !, &&, bool(), ||, <, > , !=
const equals = {
  type: 2,
  token: '==',
  show: 'equals',
  value(a, b) {
    return Number(a === b);
  }
};

const not = {
  type: 7,
  token: '!',
  show: 'not',
  value: a => Number(!a)
};

const and = {
  type: 2,
  token: '&&',
  show: 'and',
  value: (a, b) => Number(a && b)
};

const bool = {
  type: 0,
  token: 'bool',
  show: 'boolean',
  value: a => Number(!!a)
};

const or = {
  type: 2,
  token: '||',
  show: 'or',
  value: (a, b) => Number(a || b)
};

const notEquals = {
  type: 2,
  token: '!=',
  show: 'notEquals',
  value: (a, b) => Number(a !== b)
};

const greater = {
  type: 2,
  token: '>',
  show: 'greater',
  value: (a, b) => Number(a > b)
};

const less = {
  type: 2,
  token: '<',
  show: 'less',
  value: (a, b) => Number(a < b)
};

mexp.addToken([equals, bool, not, or, and, notEquals, greater, less]);

export default mexp;
