export default function getInitialState(initialState = {}) {
  const { project, configuration } = initialState;
  const active = configuration
    ? project?.settings?.definitions?.resultsScreen
    : project?.settings?.definitions?.initialScreen;

  return {
    ...initialState,
    screen: active ? { active } : undefined,
    selectedOptions: configuration?.selectedOptions,
    configuration: configuration ? { id: configuration._id, contacted: configuration.contacted } : undefined
  };
}
