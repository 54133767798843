import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';

import { useModelParameters } from '../../../helpers/hooks';

import useHouseModel from './useHouseModel';

import './Model.css';

const modelViewer = b.with('model-viewer');

const Model = ({ index = -1, preview }) => {
  const houseParameters = useModelParameters(index);

  const canvasContainerRef = useHouseModel(houseParameters);

  return <div className={modelViewer({ preview })} ref={canvasContainerRef} />;
};

Model.propTypes = {
  index: PropTypes.number,
  preview: PropTypes.bool
};

export default Model;
