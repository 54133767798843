import React from 'react';
import PropTypes from 'prop-types';

import { useInterpolatedString } from '../../../helpers/hooks';

const Markup = ({ value, interpolate }) => {
  const html = useInterpolatedString(value, interpolate);

  // eslint-disable-next-line react/no-danger
  return <div className="static" dangerouslySetInnerHTML={{ __html: html }} />;
};

Markup.propTypes = {
  value: PropTypes.string.isRequired,
  interpolate: PropTypes.bool
};

export default Markup;
