import { useRef } from 'react';

import useModel from './useModel';
import useModelLoader from './useModelLoader';

export default houseParameters => {
  const canvasContainerRef = useRef();

  const { modelGroup, camera } = useModelLoader('/static/models/housemodel.glb');

  useModel(canvasContainerRef, modelGroup, camera, houseParameters);

  return canvasContainerRef;
};
