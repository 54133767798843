import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useCalculatedValue, useSelectedOption } from '../../../helpers/hooks';
import { Slider } from '../../Atoms';
import { selectSelectedOptions } from '../../../redux/selectors';
import { setOptions } from '../../../redux/actions';

const CtrlSlider = ({
  name,
  title,
  step,
  min,
  max,
  def,
  unit,
  parentLimits,
  parentField,
  relatedSliders = [],
  maxTotal
}) => {
  const { value, setValue } = useSelectedOption(name);
  const parentValue = useCalculatedValue(parentField);
  const selectedOptions = useSelector(selectSelectedOptions);
  const dispatch = useDispatch();

  const limits = useMemo(() => {
    if (parentValue) {
      return parentLimits[parentValue];
    }

    return { min, max };
  }, [parentLimits, max, min, parentValue]);

  const handleChangeRelatedSliders = useCallback(
    sliderValue => {
      const sliders = relatedSliders
        .map(key => ({ key, value: selectedOptions[key] }))
        .filter(slider => slider.value)
        .sort((a, b) => b.value - a.value);

      const slidersSum = sliders.reduce((sum, slider) => sum + slider.value, 0);
      const total = slidersSum + sliderValue;

      if (total <= maxTotal) {
        setValue(sliderValue);

        return;
      }

      const options = { [name]: sliderValue };
      let delta = total - maxTotal;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < sliders.length; i++) {
        const slider = sliders[i];
        const deltaBySlider = Math.ceil(delta / sliders.length - i);
        const newValue = slider.value < deltaBySlider ? slider.value : slider.value - deltaBySlider;

        delta -= slider.value - newValue;

        options[slider.key] = i === sliders.length - 1 && delta ? newValue - delta : newValue;

        if (delta === 0) break;
      }
      dispatch(setOptions(options));
    },
    [dispatch, maxTotal, name, relatedSliders, selectedOptions, setValue]
  );

  const handleChangeValue = useCallback(
    sliderValue => {
      if (!relatedSliders.length || !maxTotal) {
        setValue(sliderValue);

        return;
      }

      handleChangeRelatedSliders(sliderValue);
    },
    [handleChangeRelatedSliders, maxTotal, relatedSliders.length, setValue]
  );

  return (
    <Slider
      value={value}
      onChange={handleChangeValue}
      title={title}
      step={step}
      def={def}
      min={limits.min}
      max={limits.max}
      unit={unit}
    />
  );
};

CtrlSlider.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  def: PropTypes.number,
  unit: PropTypes.string,
  parentField: PropTypes.string,
  parentLimits: PropTypes.shape({}),
  relatedSliders: PropTypes.arrayOf(PropTypes.string),
  maxTotal: PropTypes.number
};

export default CtrlSlider;
