import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../Atoms';

const STATE = {
  OPENED: 'opened',
  CLOSED: 'closed'
};

const ActionExpand = ({ args = [], ...rest }) => {
  const [id] = args;

  const onClick = useCallback(() => {
    const element = document.getElementById(id);

    if (!element) return;

    const { state } = element.dataset;

    if (state === STATE.OPENED) {
      element.style.height = '0px';
      element.dataset.state = STATE.CLOSED;
    } else {
      element.style.height = `${element.scrollHeight}px`;
      element.dataset.state = STATE.OPENED;
    }
  }, [id]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button onClick={onClick} {...rest} />;
};

ActionExpand.propTypes = {
  args: PropTypes.arrayOf(PropTypes.string)
};

export default ActionExpand;
