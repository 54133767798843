import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';

import { Input } from '../../Atoms';
import { useForm } from '../../../helpers/hooks';
import { FORM_TYPES } from '../../../helpers/types';

import './CtrlForm.css';

const ctrlForm = b.with('ctrl-form');

const FIELD_TYPES = {
  INPUT: 'input'
};

const FIELD_COMPONENTS = {
  [FIELD_TYPES.INPUT]: Input
};

const CtrlForm = ({ type, sections, successScreen }) => {
  const { onSubmit, onChangeFormField, values } = useForm(type, sections, successScreen);

  return (
    <form className={ctrlForm()} id={type} onSubmit={onSubmit}>
      <button id={`${type}-btn`} type="submit" style={{ display: 'none' }}>
        Hidden submit button
      </button>
      {sections.map(({ header, fields }) => (
        <div key={header} className={ctrlForm('section')}>
          {/* eslint-disable-next-line react/no-danger */}
          {header ? <p className={ctrlForm('header')} dangerouslySetInnerHTML={{ __html: header }} /> : null}
          <div className={ctrlForm('fields')}>
            {fields.map(
              ({ type: fieldType, options: { label, type: inputType, name, placeholder, required, multiline } }) => {
                const Component = FIELD_COMPONENTS[fieldType];

                return Component ? (
                  <Component
                    key={name}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    type={inputType}
                    required={required}
                    value={values[name]}
                    onChange={onChangeFormField}
                    multiline={multiline}
                  />
                ) : null;
              }
            )}
          </div>
        </div>
      ))}
    </form>
  );
};

CtrlForm.propTypes = {
  type: PropTypes.oneOf([FORM_TYPES.CONTACT_FORM, FORM_TYPES.SAVE_FORM]).isRequired,
  successScreen: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf([FIELD_TYPES.INPUT, FIELD_TYPES.SLIDER]).isRequired,
          options: PropTypes.shape({
            name: PropTypes.string.isRequired,

            // For input
            type: PropTypes.oneOf(['email', 'text']),
            label: PropTypes.string,
            placeholder: PropTypes.string,
            required: PropTypes.bool,
            multiline: PropTypes.bool
          })
        })
      ).isRequired
    })
  )
};

export default CtrlForm;
