import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../Atoms';
import { useSetScreen } from '../../helpers/hooks';
import { selectPreviousScreen } from '../../redux/selectors';

const ActionBack = ({ ...rest }) => {
  const previousScreen = useSelector(selectPreviousScreen);
  const setScreen = useSetScreen(previousScreen, false);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button onClick={setScreen} {...rest} />;
};

ActionBack.propTypes = {};

export default ActionBack;
