import {
  SET_SCREEN,
  SET_OPTION,
  SET_OPTIONS,
  CLEAR_INTERNAL_OPTIONS,
  UPDATE_INTERNAL_OPTION,
  SET_FORM_FIELD,
  INITIALIZE_FORM,
  CREATE_CONFIGURATION,
  CREATE_CONFIGURATION__REQUEST,
  CREATE_CONFIGURATION__SUCCESS,
  SEND_REQUEST,
  SEND_REQUEST__SUCCESS,
  SEND_REQUEST__REQUEST
} from './constants';

export const setScreen = screen => ({
  type: SET_SCREEN,
  payload: { screen }
});

export const setOption = (key, value) => ({
  type: SET_OPTION,
  payload: { key, value }
});

export const setOptions = options => ({
  type: SET_OPTIONS,
  payload: { ...options }
});

export const clearInternalOptions = () => ({
  type: CLEAR_INTERNAL_OPTIONS
});

export const updateInternalOption = (prefix, field, value) => ({
  type: UPDATE_INTERNAL_OPTION,
  payload: { prefix, field, value }
});

export const setFormField = (type, key, value) => ({
  type: SET_FORM_FIELD,
  payload: { type, key, value }
});

export const initializeForm = (type, requiredFields, values = {}) => ({
  type: INITIALIZE_FORM,
  payload: { type, requiredFields, values }
});

export const createConfiguration = successScreen => ({
  type: CREATE_CONFIGURATION,
  payload: { successScreen }
});

export const createConfigurationRequest = () => ({
  type: CREATE_CONFIGURATION__REQUEST
});

export const createConfigurationSuccess = id => ({
  type: CREATE_CONFIGURATION__SUCCESS,
  payload: { id }
});

export const sendRequest = successScreen => ({
  type: SEND_REQUEST,
  payload: { successScreen }
});

export const sendRequestRequest = () => ({
  type: SEND_REQUEST__REQUEST
});

export const sendRequestSuccess = () => ({
  type: SEND_REQUEST__SUCCESS
});
