import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import { selectCalculationsSettings, selectSelectedOptions } from './main';
import { selectConfigurationId, selectConfigurationContacted } from './configuration';

const isFalse = value => !value;

const DEFINITIONS = {
  isTrue: value => Boolean(value),
  isFalse,
  not: isFalse,
  length: (arr = []) => (Array.isArray(arr) ? arr.length : 0),
  more: (a, b) => a > b,
  value: value => value,
  equal: (a, b) => a === b,
  multiple: (a, b) => a * b,
  multipleArrayFields: (arr = [], ...fields) =>
    arr.map(item => fields.reduce((result, field) => result * item[field], 1)),
  arrayValues: (arr = [], field) => arr.map(item => item[field]),
  sum: (...arr) => arr.reduce((a, b) => a + b, 0), // regular addition
  sumArray: (arr = []) => arr.reduce((a, b) => a + b, 0), // for summing each field in array where it's all in first argument
  sumFields: (arr = [], field) => DEFINITIONS.sumArray(DEFINITIONS.arrayValues(arr, field)),
  pluralize: (word, number, inclusive) => pluralize(word, number, inclusive),
  round: value => Math.round(value)
};

const selectServiceFields = createSelector(selectConfigurationId, selectConfigurationContacted, (id, contacted) => ({
  $isConfigurationSaved: Boolean(id),
  $hasContactRequest: Boolean(contacted)
}));

// eslint-disable-next-line import/prefer-default-export
export const selectCalculatedValues = createSelector(
  selectSelectedOptions,
  selectServiceFields,
  selectCalculationsSettings,
  (selectedOptions, serviceFields, calculations) => {
    const result = { ...selectedOptions, ...serviceFields };

    calculations.forEach(({ id, type, inputs }) => {
      const func = DEFINITIONS[type];

      if (!func) return;

      const inputValues = type === 'value' ? [...inputs] : inputs.map(name => result[name]);

      result[id] = func(...inputValues);
    });

    return result;
  }
);
