import { SET_OPTION, SET_OPTIONS, CLEAR_INTERNAL_OPTIONS, UPDATE_INTERNAL_OPTION } from '../constants';
import { generateInternalField, generateInternalIndexField } from '../../helpers/utils';

export const initialState = {};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_OPTION: {
      return { ...state, [payload.key]: payload.value };
    }
    case SET_OPTIONS: {
      return { ...state, ...payload };
    }
    case CLEAR_INTERNAL_OPTIONS: {
      const cleared = Object.keys(state).reduce((result, key) => {
        if (!/^_/g.test(key)) {
          // eslint-disable-next-line no-param-reassign
          result[key] = state[key];
        }

        return result;
      }, {});

      return { ...cleared };
    }
    case UPDATE_INTERNAL_OPTION: {
      const { prefix, field, value } = payload;
      const indexField = generateInternalIndexField(prefix);

      return {
        ...state,
        [generateInternalField(prefix, field)]: value,
        [prefix]: state[prefix].map((item, index) => (index === state[indexField] ? { ...item, [field]: value } : item))
      };
    }
    default:
      return state;
  }
};
