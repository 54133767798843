import { call, takeLatest, select, delay } from 'redux-saga/effects';

import { selectConfigurationId, selectSelectedOptions } from '../redux/selectors';
import { SET_OPTION, SET_OPTIONS } from '../redux/constants';
import api from '../helpers/api';

export function* updateConfigurationSaga() {
  yield delay(2500);

  const id = yield select(selectConfigurationId);

  if (!id) return;

  const selectedOptions = yield select(selectSelectedOptions);

  yield call(api.updateConfiguration, id, { selectedOptions });
}

export default function* () {
  yield takeLatest([SET_OPTION, SET_OPTIONS], updateConfigurationSaga);
}
