import React, { useMemo } from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';

import { selectActiveScreen, selectProgressSettings } from '../../redux/selectors';
import { useSetScreen } from '../../helpers/hooks';

import './Progress.css';

const progressStyle = b.with('progress');

const Progress = () => {
  const activeScreen = useSelector(selectActiveScreen);
  const progressSettings = useSelector(selectProgressSettings);

  const TOTAL_STEPS = useMemo(() => Math.max(...Object.values(progressSettings).map(({ step }) => step)), [
    progressSettings
  ]);
  const currentStep = progressSettings[activeScreen];

  const setScreen = useSetScreen(currentStep?.previous, false);

  if (!currentStep) return null;

  const width = (currentStep.step * 100) / TOTAL_STEPS;

  return (
    <div className={progressStyle()}>
      <div className={progressStyle('bar')}>
        <div className={progressStyle('bar', { active: true })} style={{ width: `${width}%` }} />
      </div>
      <div className={progressStyle('content')}>
        {currentStep.previous ? (
          <button type="button" className={progressStyle('back')} onClick={setScreen}>
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.25789 7.37804L7.39 0.257485C7.73385 -0.0858285 8.29165 -0.0858285 8.63551 0.257485L9.46743 1.08811C9.81092 1.43105 9.81129 1.98651 9.4689 2.33019L3.81642 7.99982L9.46853 13.6698C9.81129 14.0135 9.81055 14.5689 9.46706 14.9119L8.63514 15.7425C8.29128 16.0858 7.73349 16.0858 7.38963 15.7425L0.25789 8.62159C-0.0859633 8.27828 -0.0859633 7.72135 0.25789 7.37804Z"
                fill="currentColor"
              />
            </svg>
            <span className={progressStyle('label')}>Back</span>
          </button>
        ) : null}
        <span className={progressStyle('text')}>{Math.round(width)} % complete</span>
      </div>
    </div>
  );
};

export default Progress;
