import { useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  setScreen,
  setOption,
  clearInternalOptions,
  updateInternalOption,
  initializeForm,
  setFormField,
  createConfiguration,
  sendRequest
} from '../redux/actions';
import {
  selectHelmetSettings,
  selectCalculatedValues,
  selectSelectedOption,
  selectSelectedOptions,
  selectFormValues,
  selectModelSettings,
  selectHubPreviewSettings
} from '../redux/selectors';
import GIA from '../components/Content/Model/GIA';

import { generateInternalField, generateInternalIndexField, supplant } from './utils';
import { FORM_TYPES } from './types';

export function useCalculatedValues() {
  return useSelector(selectCalculatedValues);
}

export function useCalculatedValue(key = '') {
  const values = useSelector(selectCalculatedValues);

  return key ? values[key] : undefined;
}

export function useHelmet() {
  return useSelector(selectHelmetSettings);
}

export function useSelectedOption(key) {
  const dispatch = useDispatch();

  const setValue = useCallback(
    value => {
      dispatch(setOption(key, value));
    },
    [dispatch, key]
  );

  const value = useSelector(state => selectSelectedOption(state, key));

  return { value, setValue };
}

export function useSetScreen(screen, clearOptions = true) {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setScreen(screen));

    if (clearOptions) {
      dispatch(clearInternalOptions());
    }
  }, [clearOptions, dispatch, screen]);
}

export function useUpdateInternalOption(prefix, field) {
  const dispatch = useDispatch();
  const internalField = useMemo(() => generateInternalField(prefix, field), [prefix, field]);

  const setValue = useCallback(
    value => {
      dispatch(updateInternalOption(prefix, field, value));
    },
    [dispatch, field, prefix]
  );

  const value = useSelector(state => selectSelectedOption(state, internalField));

  return { value, setValue };
}

export function useAddInternalOptions(prefix, fieldNames) {
  const { numberOfBedrooms, numberOfOccupants, numberOfFloors } = fieldNames;
  const dispatch = useDispatch();
  const selectedOptions = useSelector(selectSelectedOptions);

  return useCallback(() => {
    const regexp = new RegExp(`^_${prefix}\\|`, 'g');
    let index = -1;

    const newItem = Object.keys(selectedOptions).reduce((result, key) => {
      if (regexp.test(key)) {
        const newKey = key.replace(regexp, '');

        if (newKey === 'index') {
          index = selectedOptions[key];
        } else {
          // eslint-disable-next-line no-param-reassign
          result[newKey] = selectedOptions[key];
        }
      }

      return result;
    }, {});

    const { area1Story, area2Story, area3Story } = GIA.find(
      item => item.bedroomCount === newItem[numberOfBedrooms] && item.personCount === newItem[numberOfOccupants]
    );
    const areas = [area1Story, area2Story, area3Story];

    newItem.area = areas[newItem[numberOfFloors] - 1];

    const value = [...(selectedOptions[prefix] || [])];

    const canvas = document.getElementById('model-canvas');

    newItem.cover = canvas.toDataURL();

    if (index === -1) {
      value.push(newItem);
    } else {
      value[index] = newItem;
    }

    dispatch(setOption(prefix, value));

    if (index === -1) {
      dispatch(setOption(generateInternalIndexField(prefix), value.length - 1));
    }
  }, [dispatch, numberOfBedrooms, numberOfFloors, numberOfOccupants, prefix, selectedOptions]);
}

export function useInterpolatedString(str, interpolate = false) {
  const calculatedValues = useCalculatedValues();

  if (!interpolate) {
    return str;
  }

  return supplant(str, calculatedValues);
}

function useInitializeForm(type, sections) {
  const dispatch = useDispatch();

  useEffect(() => {
    const requiredFields = sections.reduce((result, current) => {
      current.fields.forEach(field => {
        if (field.options.required) {
          result.push(field.options.name);
        }
      });

      return result;
    }, []);

    dispatch(initializeForm(type, requiredFields));
  }, [dispatch, sections, type]);
}

export function useForm(type, sections, successScreen) {
  useInitializeForm(type, sections);

  const dispatch = useDispatch();
  const values = useSelector(state => selectFormValues(state, type));

  const onChangeFormField = useCallback(
    (value, key) => {
      dispatch(setFormField(type, key, value));
    },
    [dispatch, type]
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      if (type === FORM_TYPES.SAVE_FORM) {
        dispatch(createConfiguration(successScreen));
      }

      if (type === FORM_TYPES.CONTACT_FORM) {
        dispatch(sendRequest(successScreen));
      }
    },
    [dispatch, successScreen, type]
  );

  return { values, onChangeFormField, onSubmit };
}

export function useModelParameters(index) {
  const { listName, fields } = useSelector(selectModelSettings);
  const selectedOptions = useCalculatedValues(); // give model access to calculations as well

  return useMemo(
    () =>
      Object.keys(fields).reduce((result, field) => {
        const item = fields[field];
        const fieldName = item.child ? generateInternalField(listName, item.key) : item.key;
        const value =
          index !== -1 && item.child ? selectedOptions[listName][index][item.key] : selectedOptions[fieldName];

        // eslint-disable-next-line no-param-reassign
        result[field] = item.values ? item.values[value] : value;

        return result;
      }, {}),
    [fields, index, listName, selectedOptions]
  );
}

export function useHubPreviewSettings(name) {
  const settings = useSelector(selectHubPreviewSettings) || [];

  return useMemo(() => settings.find(hub => hub.name === name), [name, settings]);
}
