const checkStatus = response => {
  if (response.ok) {
    return response;
  }

  const error = new Error(response.statusText);

  error.response = response;

  throw error;
};
const parseJSON = res => res.json();
const returnResult = result => ({ result });
const catchError = error => ({ error });

const Fetcher = {
  get: path => fetch(path, { method: 'GET' }).then(checkStatus).then(parseJSON).then(returnResult).catch(catchError),
  post: (path, body) =>
    fetch(path, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
      .then(checkStatus)
      .then(parseJSON)
      .then(returnResult)
      .catch(catchError),
  put: (path, body) =>
    fetch(path, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
      .then(checkStatus)
      .then(parseJSON)
      .then(returnResult)
      .catch(catchError)
};

const API = {
  createConfiguration: body => Fetcher.post('/api/configurations', body),
  updateConfiguration: (id, body) => Fetcher.put(`/api/configurations/${id}`, body),
  sendRequest: (id, body) => Fetcher.put(`/api/configurations/${id}/send-request`, body)
};

export default API;
