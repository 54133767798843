import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './SuitableModels.css';
import { useCalculatedValue } from '../../../helpers/hooks';

const suitableModelsStyle = b.with('suitable-models');

const SuitableModels = ({ title, models, triggerField, visibleCount = 3 }) => {
  const [visibleModels, setVisibleModels] = useState(models);
  const value = useCalculatedValue(triggerField);

  useEffect(() => {
    setVisibleModels(models.sort(() => Math.random() - 0.5).slice(0, visibleCount));
  }, [models, visibleCount, value]);

  return (
    <div className={suitableModelsStyle()}>
      <span className={suitableModelsStyle('tile')}>{title}</span>
      <div className={suitableModelsStyle('body')}>
        {visibleModels.map(model => (
          <a
            href={model.link}
            target="_blank"
            rel="noopener noreferrer"
            className={suitableModelsStyle('item')}
            style={{ backgroundImage: `url(${model.img})` }}
          >
            <svg
              className={suitableModelsStyle('link-icon')}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.66667 0.560303V2.22697H12.6583L4.46667 10.4186L5.64167 11.5936L13.8333 3.40197V6.39364H15.5V0.560303H9.66667ZM13.8333 13.8936H2.16667V2.22697H8V0.560303H2.16667C1.24167 0.560303 0.5 1.30197 0.5 2.22697V13.8936C0.5 14.3357 0.675595 14.7596 0.988155 15.0721C1.30072 15.3847 1.72464 15.5603 2.16667 15.5603H13.8333C14.2754 15.5603 14.6993 15.3847 15.0118 15.0721C15.3244 14.7596 15.5 14.3357 15.5 13.8936V8.0603H13.8333V13.8936Z"
                fill="currentColor"
              />
            </svg>
          </a>
        ))}
      </div>
    </div>
  );
};

SuitableModels.propTypes = {
  title: PropTypes.string.isRequired,
  models: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired,
  triggerField: PropTypes.string.isRequired,
  visibleCount: PropTypes.number
};

export default SuitableModels;
