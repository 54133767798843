import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import debounce from 'lodash.debounce';

import './Input.css';

const input = b.with('input');

const Input = ({ onChange, name, placeholder = '', value = '', type = 'text', required = false, label, multiline }) => {
  const debounceOnChange = useMemo(() => debounce(onChange, 250), [onChange]);
  const handleChange = useCallback(
    e => {
      debounceOnChange(e.target.value, name);
    },
    [debounceOnChange, name]
  );

  return (
    <div className={input()}>
      {label ? (
        <label htmlFor={name} className={input('label')}>
          {label} {required ? '*' : ''}
        </label>
      ) : (
        label
      )}
      {multiline ? (
        <textarea
          defaultValue={value}
          onChange={handleChange}
          className={input('control', { multiline })}
          placeholder={placeholder}
          required={required}
          rows="4"
        />
      ) : (
        <input
          defaultValue={value}
          onChange={handleChange}
          className={input('control')}
          placeholder={placeholder}
          type={type}
          required={required}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(['email', 'text', 'url']),
  required: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool
};

export default Input;
