import { call, put, select, cancel, takeLatest } from 'redux-saga/effects';

import {
  createConfigurationRequest,
  createConfigurationSuccess,
  sendRequestRequest,
  sendRequestSuccess,
  setScreen
} from '../redux/actions';
import { SEND_REQUEST } from '../redux/constants';
import {
  selectSelectedOptions,
  selectProject,
  selectContactFormValues,
  selectConfigurationId
} from '../redux/selectors';
import api from '../helpers/api';

export function* sendRequestSaga({ payload }) {
  const selectedOptions = yield select(selectSelectedOptions);
  const project = yield select(selectProject);
  const form = yield select(selectContactFormValues);

  const body = { selectedOptions, project, ...form };

  let id = yield select(selectConfigurationId);

  if (!id) {
    yield put(createConfigurationRequest());

    const { result } = yield call(api.createConfiguration, body);

    if (result) {
      id = result;

      yield put(createConfigurationSuccess(result));
    } else {
      yield cancel();
    }
  }

  yield put(sendRequestRequest());

  const { result } = yield call(api.sendRequest, id, form);

  if (result) {
    yield put(sendRequestSuccess(result));
    yield put(setScreen(payload.successScreen));
  }
}

export default function* () {
  yield takeLatest(SEND_REQUEST, sendRequestSaga);
}
