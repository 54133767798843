import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button } from '../Atoms';
import { isSubmitFormButtonDisabled } from '../../redux/selectors';

const ActionSubmitForm = ({ args = [], ...rest }) => {
  const [type] = args;
  const disabled = useSelector(state => isSubmitFormButtonDisabled(state, type));

  const onClick = useCallback(() => {
    const btn = document.getElementById(`${type}-btn`);

    if (!btn) return;

    btn.click();
  }, [type]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button onClick={onClick} disabled={disabled} {...rest} />;
};

ActionSubmitForm.propTypes = {
  args: PropTypes.arrayOf(PropTypes.string)
};

export default ActionSubmitForm;
