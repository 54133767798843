import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import ControlTitle from '../ControlTitle';
import Switch, { TYPE_CHECKBOX, TYPE_RADIO } from '../Switch';

import './SwitchList.css';

const optionListStyle = b.with('switch-list');

const SwitchList = ({ title, name, onSelect, options, type, value }) => {
  const isRadioType = type === TYPE_RADIO;
  const [current, setCurrent] = useState(value || (isRadioType ? options[0].value : {}));

  useEffect(() => {
    onSelect(current, name);
  }, [current, name, onSelect]);

  const handleChangeCheckboxValue = useCallback((key, _, checked) => {
    setCurrent(prevState => ({ ...prevState, [key]: checked }));
  }, []);

  return (
    <div className={optionListStyle()}>
      <ControlTitle text={title} />
      <div className={optionListStyle('options')}>
        {isRadioType
          ? options.map(item => (
              <div className={optionListStyle('option')} key={item.value}>
                <Switch
                  onChange={setCurrent}
                  name={name}
                  type="radio"
                  checked={item.value === value}
                  caption={item.label}
                  value={item.value}
                />
              </div>
            ))
          : options.map(item => (
              <div className={optionListStyle('option')} key={item.value}>
                <Switch
                  onChange={handleChangeCheckboxValue}
                  name={name}
                  type="checkbox"
                  checked={current[item.value]}
                  caption={item.label}
                  value={item.value}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

SwitchList.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.oneOf([TYPE_CHECKBOX, TYPE_RADIO]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})])
};

export default SwitchList;
