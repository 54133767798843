import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import { ACTION_TYPES } from '../../../helpers/types';
import { ActionAddTo, ActionBack, ActionExpand, ActionLink, ActionStep, ActionSubmitForm } from '../../Actions';
import Directive from '../../Directive';

const ACTIONS = {
  [ACTION_TYPES.SUBMIT_FORM]: ActionSubmitForm,
  [ACTION_TYPES.ADD_TO]: ActionAddTo,
  [ACTION_TYPES.EXPAND]: ActionExpand,
  [ACTION_TYPES.LINK]: ActionLink,
  [ACTION_TYPES.STEP]: ActionStep,
  [ACTION_TYPES.BACK]: ActionBack
};

const screenActionStyle = b.with('screen');

const ScreenActions = ({ actions }) => {
  return actions.map(({ type, props, directive }, index) => {
    const Component = ACTIONS[type];

    if (!Component) return null;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <Directive directive={directive} key={index}>
        {directiveProps => (
          <div className={screenActionStyle('action')}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...props} {...directiveProps} />{' '}
          </div>
        )}
      </Directive>
    );
  });
};

ScreenActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ScreenActions;
