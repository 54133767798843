import { INITIALIZE_FORM, SET_FORM_FIELD, SET_SCREEN } from '../constants';
import { FORM_TYPES } from '../../helpers/types';

export const initialState = {
  [FORM_TYPES.SAVE_FORM]: {
    requiredFields: [],
    values: {}
  },
  [FORM_TYPES.CONTACT_FORM]: {
    requiredFields: [],
    values: {}
  }
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case INITIALIZE_FORM: {
      const { type: formType, requiredFields } = payload;

      return { ...state, [formType]: { ...state[formType], requiredFields } };
    }
    case SET_SCREEN: {
      return { ...initialState };
    }
    case SET_FORM_FIELD: {
      const { type: formType, key, value } = payload;

      return { ...state, [formType]: { ...state[formType], values: { ...state[formType].values, [key]: value } } };
    }
    default:
      return state;
  }
};
