import PropTypes from 'prop-types';

import { useCalculatedValues } from '../../helpers/hooks';

const DIRECTIVE_TYPES = {
  HIDE_IF: 'hideIf',
  DISPLAY_IF: 'displayIf',
  DISABLE_IF: 'disableIf'
};

const Directive = ({ children, directive }) => {
  const props = {};
  const calculatedValues = useCalculatedValues();

  if (!directive) return children(props);

  const { type, key } = directive;

  if (
    (type === DIRECTIVE_TYPES.HIDE_IF && calculatedValues[key]) ||
    (type === DIRECTIVE_TYPES.DISPLAY_IF && !calculatedValues[key])
  ) {
    return null;
  }

  props.disabled = type === DIRECTIVE_TYPES.DISABLE_IF && calculatedValues[key];

  return children(props);
};

Directive.propTypes = {
  directive: PropTypes.shape({
    type: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }),
  children: PropTypes.func.isRequired
};

export default Directive;
