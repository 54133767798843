export const CONTENT_TYPES = {
  EXPAND: 'expand',
  HEADER: 'header',
  INLINE_ALERT: 'inline-alert',
  MARKUP: 'markup',
  MODEL: 'model',
  PARAGRAPH: 'paragraph',
  SUBHEADER: 'subheader',
  SUMMARY: 'summary',
  SUITABLE_MODELS: 'suitable-models',
  CTRL_COUNTER: 'ctrl-counter',
  CTRL_FORM: 'ctrl-form',
  CTRL_HUB: 'ctrl-hub',
  CTRL_OPTION_LIST: 'ctrl-option-list',
  CTRL_SLIDER: 'ctrl-slider'
};

export const ACTION_TYPES = {
  SUBMIT_FORM: 'submit-form',
  EXPAND: 'expand',
  LINK: 'link',
  STEP: 'step',
  BACK: 'back',
  ADD_TO: 'add-to'
};

export const FORM_TYPES = {
  CONTACT_FORM: 'contact',
  SAVE_FORM: 'save'
};
