import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useSelectedOption, useSetScreen } from '../../../helpers/hooks';
import { clearInternalOptions, setOptions } from '../../../redux/actions';
import { generateInternalField } from '../../../helpers/utils';

import ctrlHubStyle from './style';
import CtrlHubPreview from './CtrlHubPreview';

const CtrlHub = ({ name, childScreen, countField, withPreview }) => {
  const dispatch = useDispatch();
  const { value = [], setValue } = useSelectedOption(name);
  const indexField = useMemo(() => generateInternalField(name, 'index'), [name]);
  const { value: activeIndex = -1 } = useSelectedOption(indexField);
  const handleOpenEditScreen = useSetScreen(childScreen, false);
  const handleOpenNewScreen = useSetScreen(childScreen);

  const onSelectItem = useCallback(
    index => {
      const options = Object.keys(value[index]).reduce((result, key) => {
        // eslint-disable-next-line no-param-reassign
        result[generateInternalField(name, key)] = value[index][key];

        return result;
      }, {});

      options[indexField] = Number(index);

      dispatch(setOptions(options));
    },
    [dispatch, indexField, name, value]
  );
  const handleSelectItem = useCallback(
    e => {
      const { index } = e.currentTarget.dataset;

      onSelectItem(index);
    },
    [onSelectItem]
  );

  const handleDeleteHouseType = useCallback(() => {
    setValue(value.filter((item, index) => index !== activeIndex));

    if (activeIndex === 0) {
      dispatch(clearInternalOptions());
    } else {
      onSelectItem(activeIndex - 1);
    }
  }, [activeIndex, dispatch, onSelectItem, setValue, value]);

  useEffect(() => {
    if (value.length) {
      onSelectItem(activeIndex === -1 ? value.length - 1 : activeIndex);
    }

    // only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable react/no-array-index-key */
  return (
    <div className={ctrlHubStyle()}>
      <div className={ctrlHubStyle('items')}>
        {value.map((item, index) => (
          <button
            onClick={handleSelectItem}
            className={ctrlHubStyle('item', { active: activeIndex === index })}
            key={index}
            data-index={index}
            type="button"
          >
            <span className={ctrlHubStyle('count')}>{item[countField]}</span>
            <div className={ctrlHubStyle('item-model')} style={{ backgroundImage: `url(${item.cover})` }} />
          </button>
        ))}
        <button onClick={handleOpenNewScreen} className={ctrlHubStyle('item', { add: true })} type="button">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M29.5794 17.0835H17.0794V29.5835H12.9127V17.0835H0.412689V12.9168H12.9127V0.416794H17.0794V12.9168H29.5794V17.0835Z"
              fill="currentColor"
            />
          </svg>
          <span className={ctrlHubStyle('add-label')}>Add</span>
        </button>
      </div>
      {value[activeIndex] && withPreview ? (
        <CtrlHubPreview
          name={name}
          onDelete={handleDeleteHouseType}
          onEdit={handleOpenEditScreen}
          countField={countField}
        />
      ) : null}
    </div>
  );
};

CtrlHub.propTypes = {
  name: PropTypes.string.isRequired,
  childScreen: PropTypes.string.isRequired,
  countField: PropTypes.string.isRequired,
  withPreview: PropTypes.bool
};

export default CtrlHub;
