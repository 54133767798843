import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button } from '../Atoms';
import { useAddInternalOptions, useSetScreen } from '../../helpers/hooks';
import { selectPreviousScreen } from '../../redux/selectors';

const ActionAddTo = ({ args = [], ...rest }) => {
  const prefix = args[0];
  const fieldNames = {
    numberOfBedrooms: args[1],
    numberOfOccupants: args[2],
    numberOfFloors: args[3]
  };
  const addInternalOptions = useAddInternalOptions(prefix, fieldNames);
  const previousScreen = useSelector(selectPreviousScreen);
  const setScreen = useSetScreen(previousScreen, false);

  const onClick = useCallback(() => {
    addInternalOptions();
    setScreen();
  }, [addInternalOptions, setScreen]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button onClick={onClick} {...rest} />;
};

ActionAddTo.propTypes = {
  args: PropTypes.arrayOf(PropTypes.string)
};

export default ActionAddTo;
