import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Switch.css';

const switchStyle = b.with('switch');

export const TYPE_CHECKBOX = 'checkbox';
export const TYPE_RADIO = 'radio';

const Switch = ({ type, onChange, name, value, caption = '', children, checked, disabled }) => {
  const id = name + (value || '');
  const isNumber = type === TYPE_RADIO && typeof value === 'number';

  const handleChange = useCallback(
    e => {
      onChange(
        isNumber ? Number(e.currentTarget.value) : e.currentTarget.value,
        e.currentTarget.name,
        e.currentTarget.checked
      );
    },
    [isNumber, onChange]
  );

  return (
    <div className={switchStyle({ disabled })}>
      <input
        type={type}
        onChange={handleChange}
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label className={switchStyle('label')} htmlFor={id}>
        <span className={switchStyle('icon', { type, checked, disabled })} />
        {caption ? <span className={switchStyle('caption')}>{caption}</span> : children}
      </label>
    </div>
  );
};

Switch.defaultProps = {
  value: undefined,
  caption: '',
  children: null,
  checked: false,
  disabled: false
};

Switch.propTypes = {
  type: PropTypes.oneOf([TYPE_CHECKBOX, TYPE_RADIO]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caption: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Switch;
