import React from 'react';
import PropTypes from 'prop-types';

import ctrlHubStyle from '../style';
import { Counter } from '../../../Atoms';
import { useUpdateInternalOption } from '../../../../helpers/hooks';
import Model from '../../Model';

import PreviewFields from './PreviewFields';

const CtrlHubPreview = ({ name, onDelete, onEdit, countField }) => {
  const { value, setValue } = useUpdateInternalOption(name, countField);

  return (
    <div className={ctrlHubStyle('preview')}>
      <span className={ctrlHubStyle('preview-title')}>Selected type:</span>
      <div className={ctrlHubStyle('preview-body')}>
        <div className={ctrlHubStyle('preview-counter')}>
          <Counter onChange={setValue} title="House" value={value} center />
        </div>
        <div className={ctrlHubStyle('preview-content')}>
          <div className={ctrlHubStyle('preview-model')}>
            <Model />
          </div>
          <div className={ctrlHubStyle('preview-fields')}>
            <PreviewFields name={name} />
          </div>
        </div>
        <div className={ctrlHubStyle('preview-buttons')}>
          <button className={ctrlHubStyle('preview-button')} type="button" onClick={onEdit}>
            Change
          </button>
          <button className={ctrlHubStyle('preview-button')} type="button" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

CtrlHubPreview.propTypes = {
  name: PropTypes.string.isRequired,
  countField: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default CtrlHubPreview;
