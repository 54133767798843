export const SET_SCREEN = 'SET_SCREEN';
export const SET_OPTION = 'SET_OPTION';

export const SET_OPTIONS = 'SET_OPTIONS';

export const CLEAR_INTERNAL_OPTIONS = 'CLEAR_INTERNAL_OPTIONS';
export const UPDATE_INTERNAL_OPTION = 'UPDATE_INTERNAL_OPTION';

export const SET_FORM_FIELD = 'SET_FORM_FIELD';
export const INITIALIZE_FORM = 'INITIALIZE_FORM';

export const CREATE_CONFIGURATION = 'CREATE_CONFIGURATION';
export const CREATE_CONFIGURATION__REQUEST = 'CREATE_CONFIGURATION__REQUEST';
export const CREATE_CONFIGURATION__SUCCESS = 'CREATE_CONFIGURATION__SUCCESS';

export const SEND_REQUEST = 'SEND_REQUEST';
export const SEND_REQUEST__REQUEST = 'SEND_REQUEST__REQUEST';
export const SEND_REQUEST__SUCCESS = 'SEND_REQUEST__SUCCESS';
