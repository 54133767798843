import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './redux/reducer';
import getInitialState from './redux/state';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

if (process.env.NODE_ENV !== 'production') {
  const { logger } = require('redux-logger'); // eslint-disable-line global-require

  middleware.push(logger);
}

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    getInitialState(initialState),
    composeWithDevTools(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(sagas);

  return store;
}
