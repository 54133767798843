import { createSelector } from 'reselect';

import { FORM_TYPES } from '../../helpers/types';

const selectForm = (state, type) => state.form[type];

export const selectFormValues = (state, type) => selectForm(state, type).values;
export const selectFormRequiredFields = (state, type) => selectForm(state, type).requiredFields;

export const selectSaveFormValues = state => selectForm(state, FORM_TYPES.SAVE_FORM).values;
export const selectContactFormValues = state => selectForm(state, FORM_TYPES.CONTACT_FORM).values;

export const isSubmitFormButtonDisabled = createSelector(
  selectFormValues,
  selectFormRequiredFields,
  (values, requiredFields) => requiredFields.some(field => !values[field])
);
