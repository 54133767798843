import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Expand.css';

const expandStyle = b.with('expand');

const Expand = ({ id, text }) => {
  /* eslint-disable react/no-danger */
  return <div className={expandStyle()} id={id} dangerouslySetInnerHTML={{ __html: text }} style={{ height: '0px' }} />;
};

Expand.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Expand;
