import { useEffect, useCallback, useMemo } from 'react';
import { Vector3 } from 'three';

import { supplant } from '../../../helpers/utils';
import expression from '../../../helpers/expression';

import {
  METRIC_FACTOR,
  SHORT_SIDE_DIMENSION,
  WALL_THICKNESS,
  FLOOR_HEIGHT,
  ROOF_TYPES,
  FRONT_WIDTHS,
  UNIT_TYPES
} from './constants';
import GIA from './GIA';

/* eslint-disable no-param-reassign */

const transforms = {
  scale: (object, value) => {
    object.scale = new Vector3(value, value, value);
  },
  scaleX: (object, value) => {
    object.scale.x = value;
  },
  scaleY: (object, value) => {
    object.scale.y = value;
  },
  scaleZ: (object, value) => {
    object.scale.z = value;
  },
  translateX: (object, value) => {
    object.position.x = value;
  },
  translateY: (object, value) => {
    object.position.y = value;
  },
  translateZ: (object, value) => {
    object.position.z = value;
  },
  rotateX: (object, value) => {
    object.rotation.x = value;
  },
  rotateY: (object, value) => {
    object.rotation.y = value;
  },
  rotateZ: (object, value) => {
    object.rotation.z = value;
  },
  disable: (object, value) => {
    object.visible = Boolean(!value);
  },
  enable: (object, value) => {
    object.visible = Boolean(value);
  }
};

/* eslint-enable no-param-reassign */

const getHouseModelParameters = (houseParameters, gia) => {
  if (gia) {
    const { areaStorage, area1Story, area2Story, area3Story } = gia;
    const areas = [area1Story, area2Story, area3Story];
    const { floorCount = 1 } = houseParameters;

    const area = areas[floorCount - 1]; // zero based indices

    const width = SHORT_SIDE_DIMENSION;
    // main calculation for house depth
    // since we're using math expressions anyway, maybe this could be described in data model?
    // so we would have non-hardcoded variables to refer to in gltf
    const depth = ((area + areaStorage) * houseParameters.sizeCoefficient) / floorCount / width + 2 * WALL_THICKNESS;

    const height = FLOOR_HEIGHT * floorCount;

    return { width: width * METRIC_FACTOR, height: height * METRIC_FACTOR, depth: depth * METRIC_FACTOR };
  }

  return {};
};

export default (scene, modelGroup, houseParameters, onRender) => {
  // add modelGroup to scene
  useEffect(() => {
    if (scene && modelGroup) scene.add(modelGroup);

    return () => {
      if (scene && modelGroup) scene.remove(modelGroup);
    };
  }, [modelGroup, onRender, scene]);

  const gia = useMemo(
    () =>
      GIA.find(
        ({ bedroomCount, personCount }) =>
          bedroomCount === houseParameters.bedroomCount && personCount === houseParameters.personCount
      ),
    [houseParameters.bedroomCount, houseParameters.personCount]
  );

  const modelParameters = useMemo(
    () => ({
      ...houseParameters,
      ...getHouseModelParameters(houseParameters, gia),
      ...ROOF_TYPES,
      ...FRONT_WIDTHS,
      ...UNIT_TYPES
    }),
    [gia, houseParameters]
  );

  const updateModelParameters = useCallback(
    object => {
      const { userData } = object;

      Object.entries(userData).forEach(([key, value]) => {
        if (transforms[key]) {
          const supplantedValue = supplant(value, modelParameters);

          try {
            const parsedValue = expression.eval(supplantedValue);

            transforms[key](object, parsedValue);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Problem with model: ', e.message, '\n', object.name, supplantedValue);
          }
        }
      });
    },
    [modelParameters]
  );

  // change model group parameters
  useEffect(() => {
    if (modelGroup) modelGroup.traverse(updateModelParameters);

    onRender();
  }, [modelGroup, onRender, updateModelParameters]);
};
