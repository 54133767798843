import { combineReducers } from 'redux';

import configuration from './configuration';
import form from './form';
import screen from './screen';
import selectedOptions from './selectedOptions';

const reducer = combineReducers({
  configuration,
  form,
  project: (state = {}) => state,
  screen,
  selectedOptions
});

export default reducer;
