import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import { useCalculatedValues, useHubPreviewSettings } from '../../../../../helpers/hooks';
import { generateInternalField } from '../../../../../helpers/utils';
import Directive from '../../../../Directive';

import './PreviewFields.css';

const previewFieldsStyle = b.with('preview-fields');

const getValue = (value, values) => {
  if (!values) return value;

  return values.find(item => item.value === value)?.label || value;
};

const PreviewFields = ({ name }) => {
  const calculatedValues = useCalculatedValues();
  const { fields = [] } = useHubPreviewSettings(name);

  return (
    <div className={previewFieldsStyle()}>
      {fields.map(field => (
        <Directive directive={field.directive} key={field.key}>
          {() => (
            <span className={previewFieldsStyle('item')}>
              {field.label ? <span className={previewFieldsStyle('label')}>{field.label}</span> : null}
              <span className={previewFieldsStyle('value')}>
                {getValue(calculatedValues[generateInternalField(name, field.key)], field.values)}
                {/* eslint-disable-next-line react/no-danger */}
                {field.unit ? <span dangerouslySetInnerHTML={{ __html: field.unit }} /> : null}
              </span>
            </span>
          )}
        </Directive>
      ))}
    </div>
  );
};

PreviewFields.propTypes = {
  name: PropTypes.string.isRequired
};

export default PreviewFields;
