import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import ControlTitle from '../ControlTitle';

import './Slider.css';

const slider = b.with('slider');

const Slider = ({ title, name, onChange, min = 1, max = 100, value, def = min, unit = '', step = 1 }) => {
  const handleChange = useCallback(
    e => {
      onChange(Number(e.currentTarget.value), name);
    },
    [name, onChange]
  );

  useEffect(() => {
    if (value === undefined) {
      onChange(def, name);
    }
  }, [def, name, onChange, value]);

  useEffect(() => {
    if (value < min) {
      onChange(min, name);
    }

    if (value > max) {
      onChange(max, name);
    }
  }, [max, min, name, onChange, value]);

  return (
    <div className={slider()}>
      <div className={slider('header')}>
        <ControlTitle text={title} />
        {value !== undefined ? <strong>{value + unit}</strong> : null}
      </div>
      <input
        className={slider('range')}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value || def}
        onChange={handleChange}
      />
    </div>
  );
};

Slider.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  unit: PropTypes.string,
  def: PropTypes.number,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
};

export default Slider;
