export const WALL_THICKNESS = 0.3;
export const SHORT_SIDE_DIMENSION = 6;
export const FLOOR_HEIGHT = 2.7; // metres
export const METRIC_FACTOR = 1;

// need to be numerical values so the logic calculations work
export const ROOF_TYPES = {
  MONO: 0,
  GABLED: 1,
  FLAT: 2,
  GREEN: 3,
  HIP: 4
};

export const FRONT_WIDTHS = {
  NARROW: 0,
  WIDE: 1
};

export const SPACE_STANDARTS = {
  'NDSS -5%': 0.95,
  NDSS: 1,
  'NDSS +5%': 1.05
};

export const UNIT_TYPES = {
  HOUSE: 0,
  APARTMENT: 1
};
