import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './ControlTitle.css';

const controlTitleStyle = b.with('control-title');

// eslint-disable-next-line react/no-danger
const ControlTitle = ({ text }) => <h3 className={controlTitleStyle()} dangerouslySetInnerHTML={{ __html: text }} />;

ControlTitle.propTypes = {
  text: PropTypes.string.isRequired
};

export default ControlTitle;
