import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../Atoms';

const ActionLink = ({ args = [], ...rest }) => {
  const [link] = args;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button link={link} {...rest} />;
};

ActionLink.propTypes = {
  args: PropTypes.arrayOf(PropTypes.string)
};

export default ActionLink;
