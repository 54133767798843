import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Button.css';

const button = b.with('button');

const BUTTON_COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

/* eslint-disable react/button-has-type,jsx-a11y/control-has-associated-label,react/no-danger */
const Button = ({
  label,
  onClick = () => {},
  disabled = false,
  type = 'button',
  color = BUTTON_COLORS.PRIMARY,
  link
}) => {
  if (link) {
    return (
      <a className={button({ type: color })} href={link} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );
  }

  return (
    <button
      className={button({ type: color })}
      type={type}
      onClick={onClick}
      disabled={disabled}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  link: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  color: PropTypes.oneOf([BUTTON_COLORS.PRIMARY, BUTTON_COLORS.SECONDARY]),
  disabled: PropTypes.bool
};

export default Button;
