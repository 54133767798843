import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Header.css';

const headerStyle = b.with('header');

const Header = ({ text, center = false }) => (
  // eslint-disable-next-line react/no-danger
  <h1 className={headerStyle({ center })} dangerouslySetInnerHTML={{ __html: text }} />
);

Header.propTypes = {
  text: PropTypes.string.isRequired,
  center: PropTypes.bool
};

export default Header;
