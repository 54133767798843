import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../Atoms';
import { useSetScreen } from '../../helpers/hooks';

const ActionStep = ({ args = [], ...rest }) => {
  const [nextScreen] = args;
  const setScreen = useSetScreen(nextScreen);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button onClick={setScreen} {...rest} />;
};

ActionStep.propTypes = {
  args: PropTypes.arrayOf(PropTypes.string)
};

export default ActionStep;
