import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { selectActiveScreen, selectScreensSettings } from '../../redux/selectors';
import Screen from '../Screen';

const Screens = () => {
  const activeScreen = useSelector(selectActiveScreen);
  const screens = useSelector(selectScreensSettings);

  return screens.map(screen => (
    <CSSTransition key={screen.id} in={screen.id === activeScreen} timeout={450} classNames="screen" unmountOnExit>
      <Screen screen={screen} />
    </CSSTransition>
  ));
};

export default Screens;
