// probably would make sense to store this in db

export default [
  {
    bedroomCount: 1,
    personCount: 1,
    area1Story: 39,

    areaStorage: 1
  },
  {
    bedroomCount: 1,
    personCount: 2,
    area1Story: 50,
    area2Story: 58,
    areaStorage: 1.5
  },
  {
    bedroomCount: 2,
    personCount: 3,
    area1Story: 61,
    area2Story: 70,
    areaStorage: 2
  },
  {
    bedroomCount: 2,
    personCount: 4,
    area1Story: 70,
    area2Story: 79,
    areaStorage: 2
  },
  {
    bedroomCount: 3,
    personCount: 4,
    area1Story: 74,
    area2Story: 84,
    area3Story: 90,
    areaStorage: 2.5
  },
  {
    bedroomCount: 3,
    personCount: 5,
    area1Story: 86,
    area2Story: 93,
    area3Story: 99,
    areaStorage: 2.5
  },
  {
    bedroomCount: 3,
    personCount: 6,
    area1Story: 95,
    area2Story: 102,
    area3Story: 108,
    areaStorage: 2.5
  },
  {
    bedroomCount: 4,
    personCount: 5,
    area1Story: 90,
    area2Story: 98,
    area3Story: 103,
    areaStorage: 3
  },
  {
    bedroomCount: 4,
    personCount: 6,
    area1Story: 99,
    area2Story: 106,
    area3Story: 112,
    areaStorage: 3
  },
  {
    bedroomCount: 4,
    personCount: 7,
    area1Story: 108,
    area2Story: 115,
    area3Story: 121,
    areaStorage: 3
  },
  {
    bedroomCount: 4,
    personCount: 8,
    area1Story: 117,
    area2Story: 124,
    area3Story: 130,
    areaStorage: 3
  },
  {
    bedroomCount: 5,
    personCount: 6,
    area1Story: 103,
    area2Story: 110,
    area3Story: 116,
    areaStorage: 3.5
  },
  {
    bedroomCount: 5,
    personCount: 7,
    area1Story: 112,
    area2Story: 119,
    area3Story: 125,
    areaStorage: 3.5
  },
  {
    bedroomCount: 5,
    personCount: 8,
    area1Story: 121,
    area2Story: 128,
    area3Story: 134,
    areaStorage: 3.5
  },
  {
    bedroomCount: 6,
    personCount: 7,
    area1Story: 116,
    area2Story: 123,
    area3Story: 129,
    areaStorage: 4
  },
  {
    bedroomCount: 6,
    personCount: 8,
    area1Story: 125,
    area2Story: 132,
    area3Story: 138,
    areaStorage: 4
  }
];
