import React from 'react';
import PropTypes from 'prop-types';

import { useSelectedOption } from '../../../helpers/hooks';
import { Counter } from '../../Atoms';

const CtrlCounter = ({ title, name, min, max }) => {
  const { value, setValue } = useSelectedOption(name);

  return <Counter onChange={setValue} title={title} value={value} min={min} max={max} />;
};

CtrlCounter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number
};

export default CtrlCounter;
