import { useCallback, useEffect, useRef } from 'react';
import { WebGLRenderer, sRGBEncoding, Scene } from 'three';

import useParametricModel from './useParametricModel';

const useRenderer = canvasContainerRef => {
  const rendererRef = useRef();

  useEffect(() => {
    const { current: domElement } = canvasContainerRef;

    const renderer = new WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });

    renderer.outputEncoding = sRGBEncoding;
    renderer.gammaFactor = 2.2;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(domElement.clientWidth, domElement.clientHeight);

    renderer.domElement.id = 'model-canvas';

    domElement.appendChild(renderer.domElement);

    rendererRef.current = renderer;

    return () => {
      domElement.removeChild(renderer.domElement);
      renderer.dispose();
    };
  }, [canvasContainerRef]);

  return rendererRef;
};

const useScene = () => {
  const sceneRef = useRef();

  useEffect(() => {
    const scene = new Scene();

    sceneRef.current = scene;

    return () => {
      sceneRef.current = undefined;
      scene.dispose();
    };
  }, []);

  return sceneRef.current;
};

const useCamera = (camera, canvasContainerRef) => {
  // update aspect
  useEffect(() => {
    const { clientHeight, clientWidth } = canvasContainerRef.current;
    const aspect = clientWidth / clientHeight;

    if (camera) {
      // eslint-disable-next-line no-param-reassign
      camera.aspect = aspect;
      camera.updateProjectionMatrix();
    }
  }, [camera, canvasContainerRef]);

  // create orbit
};

const useThreeSetup = (camera, canvasContainerRef) => {
  // setup renderer

  const renderer = useRenderer(canvasContainerRef);

  const scene = useScene();

  // render function
  const onRender = useCallback(() => {
    if (renderer.current && scene && camera) renderer.current.render(scene, camera);
  }, [camera, renderer, scene]);

  // update camera and attach orbit

  useCamera(camera, canvasContainerRef);

  return { scene, onRender };
};

export default (canvasContainerRef, modelGroup, camera, houseParameters) => {
  // setup threejs with given camera and canvas container
  const { scene, onRender } = useThreeSetup(camera, canvasContainerRef);

  useParametricModel(scene, modelGroup, houseParameters, onRender); // put parametric model to scene
};
