import React from 'react';
import PropTypes from 'prop-types';

import { CONTENT_TYPES } from '../../../helpers/types';
import {
  CtrlCounter,
  CtrlForm,
  CtrlHub,
  CtrlOptionList,
  CtrlSlider,
  Expand,
  Header,
  InlineAlert,
  Markup,
  Model,
  Paragraph,
  SubHeader,
  SuitableModels,
  Summary
} from '../../Content';
import Directive from '../../Directive';

const CONTENT = {
  [CONTENT_TYPES.CTRL_COUNTER]: CtrlCounter,
  [CONTENT_TYPES.CTRL_FORM]: CtrlForm,
  [CONTENT_TYPES.CTRL_HUB]: CtrlHub,
  [CONTENT_TYPES.CTRL_OPTION_LIST]: CtrlOptionList,
  [CONTENT_TYPES.CTRL_SLIDER]: CtrlSlider,
  [CONTENT_TYPES.EXPAND]: Expand,
  [CONTENT_TYPES.HEADER]: Header,
  [CONTENT_TYPES.INLINE_ALERT]: InlineAlert,
  [CONTENT_TYPES.MARKUP]: Markup,
  [CONTENT_TYPES.MODEL]: Model,
  [CONTENT_TYPES.PARAGRAPH]: Paragraph,
  [CONTENT_TYPES.SUBHEADER]: SubHeader,
  [CONTENT_TYPES.SUITABLE_MODELS]: SuitableModels,
  [CONTENT_TYPES.SUMMARY]: Summary
};

const ScreenContent = ({ content }) => {
  return content.map(({ type, props, directive }, index) => {
    const Component = CONTENT[type];

    if (!Component) return null;

    return (
      // eslint-disable-next-line react/no-array-index-key
      <Directive directive={directive} key={index}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {directiveProps => <Component {...props} {...directiveProps} />}
      </Directive>
    );
  });
};

ScreenContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ScreenContent;
