import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import ControlTitle from '../ControlTitle';

import './OptionList.css';

const optionList = b.with('option-list');

const OptionList = ({ title, options, value = '', onSelect }) => {
  const [current, setCurrent] = useState(value || options[0].value);
  const handleChange = useCallback(e => {
    setCurrent(e.currentTarget.dataset.value);
  }, []);

  useEffect(() => {
    onSelect(current);
  }, [current, onSelect]);

  return (
    <div className={optionList()}>
      <ControlTitle text={title} />
      <div className={optionList('options')}>
        {options.map(option => (
          <button
            className={optionList('option', { active: value === option.value })}
            key={option.value}
            data-value={option.value}
            type="button"
            onClick={handleChange}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

OptionList.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default OptionList;
