import { CREATE_CONFIGURATION__SUCCESS, SEND_REQUEST__SUCCESS } from '../constants';

export const initialState = {
  id: '',
  contacted: false
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case CREATE_CONFIGURATION__SUCCESS: {
      return { ...state, id: payload.id };
    }
    case SEND_REQUEST__SUCCESS: {
      return { ...state, contacted: true };
    }
    default:
      return state;
  }
};
