import React from 'react';
import PropTypes from 'prop-types';

import { OptionList, SwitchList } from '../../Atoms';
import { useSelectedOption } from '../../../helpers/hooks';

const OPTION_LIST_TYPES = {
  BUTTON: 'button',
  RADIO: 'radio',
  CHECKBOX: 'checkbox'
};

const CtrlOptionList = ({ type, name, title, options }) => {
  const { value, setValue } = useSelectedOption(name);

  if (type === OPTION_LIST_TYPES.BUTTON) {
    return <OptionList value={value} onSelect={setValue} title={title} options={options} />;
  }

  return <SwitchList value={value} onSelect={setValue} title={title} options={options} name={name} type={type} />;
};

CtrlOptionList.propTypes = {
  type: PropTypes.oneOf([OPTION_LIST_TYPES.BUTTON, OPTION_LIST_TYPES.RADIO, OPTION_LIST_TYPES.CHECKBOX]).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired
};

export default CtrlOptionList;
