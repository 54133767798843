import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './InlineAlert.css';

const inlineAlertStyle = b.with('inline-alert');

const ICON_TYPES = {
  CHECK: 'check'
};

const ICON_COLORS = {
  SUCCESS: 'success'
};

const ICONS = {
  [ICON_TYPES.CHECK]: (
    <path
      d="M8.15146 17.5456L0.351457 9.74559C-0.117152 9.27698 -0.117152 8.51718 0.351457 8.04853L2.04847 6.35146C2.51708 5.88281 3.27693 5.88281 3.74554 6.35146L9 11.6059L20.2545 0.351457C20.7231 -0.117152 21.4829 -0.117152 21.9515 0.351457L23.6485 2.04852C24.1172 2.51713 24.1172 3.27693 23.6485 3.74559L9.84853 17.5456C9.37987 18.0143 8.62007 18.0143 8.15146 17.5456Z"
      fill="currentColor"
    />
  )
};

const InlineAlert = ({ text, icon = ICON_TYPES.CHECK, color = ICON_COLORS.SUCCESS }) => (
  <p className={inlineAlertStyle()}>
    <span className={inlineAlertStyle('icon', { color })}>
      <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        {ICONS[icon]}
      </svg>
    </span>
    {/* eslint-disable-next-line react/no-danger */}
    <span className={inlineAlertStyle('message')} dangerouslySetInnerHTML={{ __html: text }} />
  </p>
);

InlineAlert.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf([ICON_TYPES.CHECK]),
  color: PropTypes.oneOf([ICON_COLORS.SUCCESS])
};

export default InlineAlert;
