import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useCallback, useEffect, useState } from 'react';

const loader = new GLTFLoader();
const useModelLoader = url => {
  const [camera, setCamera] = useState();
  const [modelGroup, setModelGroup] = useState();

  const onLoad = useCallback(gltf => {
    const { scene } = gltf;

    setModelGroup(scene);

    const [newCamera] = gltf.cameras;

    setCamera(newCamera);
  }, []);

  useEffect(() => {
    loader.load(url, onLoad);
  }, [onLoad, url]);

  return { camera, modelGroup };
};

export default useModelLoader;
