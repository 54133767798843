import { call, put, select, takeLatest } from 'redux-saga/effects';

import { createConfigurationRequest, createConfigurationSuccess, setScreen } from '../redux/actions';
import { CREATE_CONFIGURATION } from '../redux/constants';
import { selectSelectedOptions, selectProject, selectSaveFormValues } from '../redux/selectors';
import api from '../helpers/api';

export function* createConfigurationSaga({ payload }) {
  const selectedOptions = yield select(selectSelectedOptions);
  const project = yield select(selectProject);
  const form = yield select(selectSaveFormValues);

  const body = { selectedOptions, project, ...form };

  yield put(createConfigurationRequest());

  const { result } = yield call(api.createConfiguration, body);

  if (result) {
    yield put(createConfigurationSuccess(result));
    yield put(setScreen(payload.successScreen));
  }
}

export default function* () {
  yield takeLatest(CREATE_CONFIGURATION, createConfigurationSaga);
}
