import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './SubHeader.css';

const subHeaderStyle = b.with('sub-header');

const SubHeader = ({ text, center = false }) => (
  // eslint-disable-next-line react/no-danger
  <h2 className={subHeaderStyle({ center })} dangerouslySetInnerHTML={{ __html: text }} />
);

SubHeader.propTypes = {
  text: PropTypes.string.isRequired,
  center: PropTypes.bool
};

export default SubHeader;
