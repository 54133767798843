export const supplant = (str, obj) => {
  return str.replace(/{([^{}]*)}/g, (a, b) => {
    const r = obj[b];

    return typeof r === 'string' || typeof r === 'number' ? r : a;
  });
};

export const generateInternalField = (prefix, field) => `_${prefix}|${field}`;
export const generateInternalIndexField = prefix => generateInternalField(prefix, 'index');
