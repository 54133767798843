import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import ControlTitle from '../ControlTitle';

import './Counter.css';

const counter = b.with('counter');

const Counter = ({ title, value, onChange, min = 1, max = 100, center }) => {
  const increment = useCallback(() => {
    onChange(value + 1 > max ? max : value + 1);
  }, [max, onChange, value]);

  const decrement = useCallback(() => {
    onChange(value - 1 < min ? min : value - 1);
  }, [min, onChange, value]);

  useEffect(() => {
    if (!value) {
      onChange(min);
    }
  }, [min, onChange, value]);

  return (
    <div className={counter({ center })}>
      <ControlTitle text={title} />
      <div className={counter('body')}>
        <button
          type="button"
          onClick={decrement}
          className={counter('button', { type: 'decrement' })}
          disabled={value === min}
        >
          <svg viewBox="0 0 24 24">
            <path d="M19,13H5V11H19V13Z" fill="currentColor" />
          </svg>
        </button>
        <span className={counter('value')}>{value}</span>
        <button
          type="button"
          onClick={increment}
          className={counter('button', { type: 'increment' })}
          disabled={value === max}
        >
          <svg viewBox="0 0 24 24">
            <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="currentColor" />
          </svg>
        </button>
      </div>
    </div>
  );
};

Counter.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  center: PropTypes.bool
};

export default Counter;
